import React from 'react';
import Home from './home/home';

import '../components/styles.css';

const IndexPage = () => (
  <Home />
);

export default IndexPage;
